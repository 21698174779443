import { Box, Image } from '@chakra-ui/react'

const GoogleIcon = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      width={24}
      height={24}
    >
      <Image src="/icon/google.svg" alt="google" />
    </Box>
  )
}

export { GoogleIcon }
