import { Box, Center, HStack, Image, Text } from '@chakra-ui/react'
import { getAuth } from 'firebase/auth'
import { useRouter } from 'next/dist/client/router'
import React from 'react'
import { loginWithGoogle } from 'src/util/firebaseHelpers'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Button } from 'src/components/Button'
import { useGetCurrentOperatorLazyQuery } from 'generated/graphql'
import { LocalStorageKeys } from 'src/util/persistenceKeys'
import routes from 'src/util/routes'
import { GoogleIcon } from 'src/components/Icon/Google'
import styles from './style.module.scss'
import Link from 'next/link'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faWaveSquare } from '@fortawesome/free-solid-svg-icons'

const LoginPage = () => {
  const [user, authLoading] = useAuthState(getAuth())
  const router = useRouter()
  const [getCurrentOperator] = useGetCurrentOperatorLazyQuery({
    onCompleted: (data) => {
      if (!data?.currentOperator?.hotelList?.items) {
        alert(
          'スタッフが登録されていない可能性があります。再読み込みして別のアカウントでログインしてください。'
        )
        throw new Error('スタッフのデータ取得に失敗しました。')
      }

      localStorage.setItem(
        LocalStorageKeys.currentManageHotelId,
        data.currentOperator?.hotelList?.items[0].id
      )

      router.push(routes.home)
    },
  })

  const onClickLogin = async () => {
    try {
      const res = await loginWithGoogle()

      if (!res) {
        throw new Error('Google認証に失敗しました')
      }

      await getCurrentOperator()
    } catch (e) {
      throw e
    }
  }

  // FIXME: ログインしてる場合はhome画面へ
  // このままだと普通にログインした時にonCompletedの処理が走らないので、今のところこうしている
  // useEffect(() => {
  //   if (!authLoading && user) {
  //     router.push(routes.home)
  //   }
  // }, [authLoading, user, router])

  return (
    <div className={styles.bg}>
      <Box className={styles.box}>
        <Box mb="40px">
          <Image src="/logo_jammy.svg" className={styles.logo} alt="Jammy" />
          <Text className={styles.logoText}>One system is Enough.</Text>
        </Box>

        <Box textAlign="left">
          <Text fontSize="16px" mb="12px" textAlign="center">
            アカウントにログイン
          </Text>
          <Center>
            <Button
              onClick={onClickLogin}
              w="100%"
              h="52px"
              size="large"
              leftIcon={<GoogleIcon />}
              className={styles.button}
            >
              <Text>Googleアカウントでログイン</Text>
            </Button>
          </Center>
        </Box>
      </Box>
      <div className={styles.footer}>
        <HStack justifyContent="space-between">
          <Box className={styles.link} mr="12px">
            <Link href="mailto:jammy@mole-inc.co" passHref>
              <HStack>
                <FontAwesomeIcon icon={faEnvelope} />
                <Text>お問合せ</Text>
              </HStack>
            </Link>
          </Box>
          <Box className={styles.link}>
            <Link href="https://mole-inc.co" passHref>
              <HStack>
                <FontAwesomeIcon icon={faWaveSquare} />
                <Text>運営企業</Text>
              </HStack>
            </Link>
          </Box>
        </HStack>
        <Box textAlign="center">
          <Text>&copy; Jammy</Text>
        </Box>
      </div>
    </div>
  )
}
export default LoginPage
